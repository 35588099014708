import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KozaTransfer from "../../../../components/koza-transfer/KozaTransfer";
import { useTranslation } from 'react-i18next';
import _ from "lodash";

const Step3 = props => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    useEffect(() => {
        var data = props.allCourses;
        if (!_.isEmpty(props.preCourses)) {
            //TODO: burası lodash difference ile yapılabilir mi?
            props.preCourses.map((item) => {
                _.remove(data, p => p.id == item);
            });
        }
        setData(data);
    }, []);

    return (
        <div>
            <KozaTransfer dataSource={data} selectedData={props.selectedData} setData={props.setCourses} titles={[t('all_courses'), t('selected_courses')]} locale={{ itemsUnit: t('course'), itemUnit: t('course') }} />
        </div>
    );
};

Step3.propTypes = {
    allCourses: PropTypes.array,
    preCourses: PropTypes.array,
    setCourses: PropTypes.func,
    selectedData: PropTypes.array
};

export default Step3;