import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Badge, Button, Col, Form, message, Row, Space, Tooltip } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import _ from "lodash";
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import KozaCard from '../../../../components/koza-card/KozaCard';
import KozaTable from '../../../../components/koza-table/KozaTable';
import DeletePopconfirm from "../../../../components/delete-popconfirm/DeletePopconfirm";
import KozaForm from "../../../../../components/koza-form/KozaForm";
import KozaFormItem from "../../../../../components/koza-form-item/KozaFormItem";
import EducationCategoryCascader from '../../../../components/custom/education-category-cascader/EducationCategoryCascader';
import KozaModal from '../../../../components/koza-modal/KozaModal';

const Index = props => {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get('/api/advancedsettings/category/getall', (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const handleSave = async (data) => {
        setLoading(true);
        await ServiceHelper.post("/api/advancedsettings/category/save", data, (status, result) => {
            if (status === 200) {
                message.success(result);
                form.resetFields();
                setShowModal(false);
                getData();
            }
        }).finally(() => setLoading(false));
    }

    const handleDeleteItem = async (id) => {
        setLoading(true)
        await ServiceHelper.post('/api/advancedsettings/category/delete', { id: id }, (status, result) => {
            if (status === 200) {
                if (status === 200) {
                    message.success(result);
                    getData();
                }
            }
        }).finally(() => setLoading(false));
    }

    const columns = [
        {
            title: t('status'),
            dataIndex: 'isActive',
            render: (item) => item ? <Badge status='success' text={t('active')} /> : <Badge status='error' text={t('passive')} />
        },
        {
            title: t('category_name'),
            dataIndex: 'name',
        },
        {
            title: t('description'),
            dataIndex: 'description',
        },
        {
            title: '',
            align: "right",
            render: (_, record) => (
                <Space size="middle">
                    <DeletePopconfirm onConfirm={() => handleDeleteItem(record.id)} />
                    {/* <a onClick={() => form.setFieldsValue(record)}>{t("edit")}</a> */}
                </Space>
            ),
        },
    ];

    return (
        <div id='category-operations-container'>
            <KozaCard title="categories" extra={<Space>
                <Tooltip title={t('refresh')}>
                    <Button shape="circle" icon={<SyncOutlined />} onClick={() => getData()} />
                </Tooltip>
                <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>{t('add')}</Button>
            </Space>}>
                <Alert type='info' message={t('category_description')} />
                <KozaTable columns={columns} dataSource={data} size="small" loading={loading} />
            </KozaCard>

            <KozaModal title='category_operations' open={showModal} confirmLoading={saveLoading} handleOk={() => form.submit()} handleCancel={() => {
                setShowModal(false);
                form.resetFields();
            }}>

                <KozaForm onFinish={handleSave} initialValues={initialValues} form={form} showButtonGroup={false}>
                    <KozaFormItem name='id' type="hidden" />
                    <Row gutter={16}>
                        <Col span={24}>
                            <KozaFormItem name='isActive' label='status' type="switch" checkedchildren="active" uncheckedchildren="passive" />
                        </Col>
                        <Col span={24}>
                            <KozaFormItem name='name' label='category_name' rules={[{ required: true, min: 3, max: 100 }]} value={initialValues.name} />
                        </Col>
                        <Col span={24}>
                            <EducationCategoryCascader name="parents" />
                        </Col>
                        <Col span={24}>
                            <KozaFormItem name='description' label='description' type="textarea" maxLength={250} />
                        </Col>
                    </Row>
                </KozaForm>
            </KozaModal>
        </div>
    );
};

const initialValues = {
    "isActive": true
}

export default Index;