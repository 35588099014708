import React from 'react';
import './AuthContainer.scss';
import PropTypes from 'prop-types';
import { Col, ConfigProvider, Image, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import XLangSelect from '../../../components/koza-lang-select/KozaLangSelect';
import Helpers from '../../../helpers/Helpers';

const AuthContainer = props => {

    const { t } = useTranslation();

    return (
        <ConfigProvider prefixCls={Helpers.prefixCls} iconPrefixCls={Helpers.prefixCls}>
            <div id='auth-container'>
                <div className='w-100'>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZA_ACIKZEMIN.svg`} alt='SİBERKOZA' id='logo' />
                    <Row className='h-100'>
                        <Col md={12} lg={14} xl={16} id='left' className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <Image src={props.image} preview={false} />
                        </Col>
                        <Col md={12} lg={10} xl={8} id='right'>
                            <div id='lang-container'>
                                <XLangSelect />
                            </div>
                            <div id='inner-container' className='d-flex align-items-center justify-content-center'>
                                {props.children}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </ConfigProvider>
    );
};

AuthContainer.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string
};

export default AuthContainer;