import React, { useEffect, useState } from 'react';
import './ProjectUsers.scss';
import PropTypes from 'prop-types';
import KozaCard from '../../../../../../components/koza-card/KozaCard';
import { useTranslation } from 'react-i18next';
import ServiceHelper from '../../../../../../../helpers/ServiceHelper';
import KozaTable from '../../../../../../components/koza-table/KozaTable';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const ProjectUsers = props => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.projectId) {
            getData();
        }
    }, []);

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get(`/api/programs/getprojectstudents?projectId=${props.projectId}`, (status, result) => {
            if (status === 200) {
                console.log(result)
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const columns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('surname'),
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title: t('course'),
            dataIndex: 'courseTitle',
            key: 'courseTitle',
        },
        {
            title: t('progress_status'),
            dataIndex: 'percentage',
            key: 'percentage',
            render: (item) => <span>%{item}</span>
        },
    ];

    return (
        <KozaCard title={t('users')} loading={loading} extra={<Tooltip title={t('refresh')}>
            <Button size='small' shape="circle" icon={<ReloadOutlined />} onClick={getData} />
        </Tooltip>
        }>
            <KozaTable dataSource={data} columns={columns} size="small" />
        </KozaCard>
    );
};

ProjectUsers.propTypes = {
    projectId: PropTypes.string.isRequired
};

export default ProjectUsers;