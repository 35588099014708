import React, { useEffect, useState } from 'react';
import './Program.scss';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Divider, message, Row, Skeleton, Typography } from 'antd';
import CourseList from './details/course-list/CourseList';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import KozaCard from '../../../../components/koza-card/KozaCard';
import { useTranslation } from 'react-i18next';
import PendingUsers from './details/pending-users/PendingUsers';
import PendingUsersCourse from './details/pending-users-course/PendingUsersCourse';
import Helpers from '../../../../../helpers/Helpers';
import ProjectUsers from './details/project-users/ProjectUsers';

const Program = () => {

    const { t } = useTranslation();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [regiserLoading, setRegiserLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(params) && params["id"]) {
            getData(params.id);
        }
    }, []);

    const getData = async (id) => {
        setLoading(true);
        await ServiceHelper.get(`/api/programs/getdetails?id=${id}`, (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const register = async () => {
        setRegiserLoading(true);
        await ServiceHelper.post('/api/programs/register', { programId: data.id }, (status, result) => {
            if (status === 200) {
                message.success(result);
                getData(data.id);
            }
        }).finally(() => setRegiserLoading(false));
    }

    return (
        loading
            ? <Skeleton active />
            : <div id='program-details-container'>
                <KozaCard classname="program-info">
                    <Divider><Typography.Title level={3} className="mt-0">{data.title}</Typography.Title></Divider>
                    <Typography.Paragraph>{data.description}</Typography.Paragraph>
                    {
                        Helpers.isUser() && <div className='text-center'>
                            {
                                data.status === 0 && <Button type='primary' danger onClick={register} loading={regiserLoading}>{t('register_program')}</Button>
                            }
                            {
                                data.status === 1 && <Alert type='warning' message={<Typography.Text strong>{t('program_status_waiting')}</Typography.Text>} className="inline-block" />
                            }
                        </div>
                    }
                </KozaCard>

                <Row gutter={16}>
                    <Col xl={12}>
                        {data.preCourses && <CourseList title='prerequisite_courses' data={data.preCourses} programId={data.id} isUser={Helpers.isUser()} getData={getData} className="mb-20" />}
                        {data.courses && <CourseList title='program_courses' data={data.courses} programId={data.id} isUser={Helpers.isUser()} getData={getData} className="mb-20"/>}
                    </Col>
                    <Col xl={12}>
                        {Helpers.isAdmin() && data.id && <PendingUsers programId={data.id} />}
                        {(Helpers.isMentor() || Helpers.isAdmin()) && data.id && <PendingUsersCourse programId={data.id} />}
                    </Col>
                    {(Helpers.isMentor() || Helpers.isAdmin()) && data.id && <Col xl={24}><ProjectUsers projectId={data.id} /> </Col>}
                </Row>
            </div>
    );
};

export default Program;