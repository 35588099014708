import React, { useEffect, useState } from 'react';
import './Roles.scss';
import { Button, message, Space, Tag, Tooltip } from 'antd';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import KozaCard from '../../../components/koza-card/KozaCard';
import KozaTable from '../../../components/koza-table/KozaTable';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import XConfirm from '../../../components/koza-confirm/KozaConfirm';
import { useNavigate } from 'react-router-dom';

const Roles = () => {

    const { t } = useTranslation();
    let navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get('/api/roles/getall', (status, result) => {
            if (status === 200) {
                setData(result);
                console.log(result)
            }
        }).finally(() => setLoading(false));
    }

    const deleteRole = async (id) => {
        setConfirmLoading(true);
        await ServiceHelper.post('/api/roles/delete', { id: id }, (status, result) => {
            if (status === 200) {
                message.success(result);
                getData();
            }
        }).finally(() => setConfirmLoading(false));
    }

    const columns = [
        {
            title: t('role_name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: item => <span>{t(item)}</span>
        },
        {
            title: t('users'),
            dataIndex: 'users',
            key: 'users',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.users - b.users,
            align: 'center',
            render: item => <Tag color='geekblue'>{item > 1 ? t('users_count', { count: item }) : `${item.length} ${t('user')}`}</Tag>
        },
        {
            title: t('description'),
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: t('action'),
            key: '',
            align: "right",
            render: (text, record) => (
                !record.isSystem && <Space size="middle">
                    <a onClick={() => navigate(`/panel/roles/edit/${record.id}`)}>{t('edit')}</a>
                    <XConfirm title={t('delete_confirm_message', { name: record.name })} oktext={t('delete')}
                        onconfirm={() => deleteRole(record.id)} loading={confirmLoading}>
                        <a>{t('delete')}</a>
                    </XConfirm>
                </Space>
            ),
        },
    ];

    const columns_users = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('surname'),
            dataIndex: 'surname',
            key: 'surname',
        },
    ];

    return (
        <KozaCard title='role_list' extra={<Space>
            {/* <Tooltip title={t('add_new')}>
                <Button shape="circle" icon={<PlusOutlined />} onClick={() => navigate('/panel/roles/add')} />
            </Tooltip> */}
            <Tooltip title={t('refresh')}>
                <Button shape="circle" icon={<ReloadOutlined />} onClick={getData} />
            </Tooltip>
        </Space>}>
            <KozaTable dataSource={data} columns={columns} isLoading={isLoading} size="small" expandedRowRender={(record) => (
                <KozaTable dataSource={record.users} columns={columns_users} isLoading={isLoading} size="small" />
            )} />
        </KozaCard>
    );
};

export default Roles;