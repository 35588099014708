class Constants {

    ROLE_SUPERADMIN = "SUPERADMIN";
    ROLE_ADMIN = "ADMIN";
    ROLE_STUDENT = "STUDENT";
    ROLE_MENTOR = "MENTOR";
    ROLE_ASSISTANT_MENTOR = "ASSISTANT_MENTOR";

    locale = "trTR";

}

export default new Constants();