import React, { useEffect, useState } from 'react';
import './UsersSelect.scss'
import PropTypes from 'prop-types';
import { Select } from 'antd';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import _ from 'lodash';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const { Option } = Select;

const UsersSelect = props => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await ServiceHelper.get(`/api/users/getusers?roleType=${props.role}`, (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <div className='users-select-container'>
            <KozaFormItem name={props.name} label={props.label} type="select" mode={props.mode} loading={loading}>
                {
                    !_.isEmpty(data) && data.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)
                }
            </KozaFormItem>
        </div>
    );
};

UsersSelect.defaultProps = {
    label: 'users',
    name: 'users',
    mode: "",
    role: ""
}

UsersSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    mode: PropTypes.string,
    role: PropTypes.string
};

export default UsersSelect;