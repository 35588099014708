import React from 'react';
import './KozaTable.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const KozaTable = props => {
    return (
        <Table dataSource={props.dataSource} columns={props.columns} loading={props.isLoading} size={props.size}
            expandable={{
                expandedRowRender: props.expandedRowRender
            }} />
    );
};

KozaTable.defaultProps = {
    dataSource: [],
    columns: []
};

KozaTable.propTypes = {
    dataSource: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    size: PropTypes.string,
    expandedRowRender: PropTypes.any
};

export default KozaTable;