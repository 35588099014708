import React, { useEffect, useState } from 'react';
import './Role.scss';
import _ from 'lodash';
import { Col, message, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import KozaCard from '../../../components/koza-card/KozaCard';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const Role = () => {

    let navigate = useNavigate();
    let params = useParams();

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [saveLoading, setSaveLoading] = useState(false);
    const [cardTitle, setCardTitle] = useState('add_new_role');

    useEffect(() => {
        if (!_.isEmpty(params) && params["id"]) {
            setCardTitle("update_role");
            getData(params.id);
        }
    }, []);

    const getData = async (id) => {
        setLoading(true);
        await ServiceHelper.get(`/api/roles/getbyid?id=${id}`, (status, result) => {
            if (status === 200) {
                setInitialValues(result);
            }
        }).finally(() => setLoading(false));
    }

    const onFinish = async (values) => {
        setSaveLoading(true);

        var url = "/api/roles/add";
        if (!_.isEmpty(params) && params["id"]) {
            url = "/api/roles/update";
            values["id"] = params["id"];
        }

        await ServiceHelper.post(url, values, (status, result) => {
            if (status === 200) {
                message.success(result);
                navigate('/panel/roles/list');
            }
        }).finally(() => setSaveLoading(false));
    };

    return (
        <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                <KozaCard title={cardTitle} loading={loading}>
                    <KozaForm onFinish={onFinish} isLoading={saveLoading} initialValues={initialValues}>
                        <KozaFormItem name='name' label='role_name' rules={[{ required: true, min: 3, max: 30 }]} />
                        <KozaFormItem name='description' label='description' type='textarea' maxLength={250} rules={[{ max: 250 }]} />
                    </KozaForm>
                </KozaCard>
            </Col>
        </Row>
    );
};

export default Role;