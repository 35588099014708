import React, { useState } from 'react';
import './CourseList.scss';
import PropTypes from 'prop-types';
import { Alert, Button, Form, List, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LockOutlined, CheckCircleOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ServiceHelper from '../../../../../../../helpers/ServiceHelper';
import KozaForm from '../../../../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../../../../components/koza-form-item/KozaFormItem';

const CourseList = props => {

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState("");

    const preCompleteCourse = async (id, certificateMandatory) => {
        setSelectedCourseId(id);
        if (certificateMandatory) {
            setOpenModal(true);
        } else {
            onFinish({ courseId: id });
        }
    }

    const onFinish = async (values) => {
        values["courseId"] = values["courseId"] || selectedCourseId;
        values["programId"] = props.programId;

        setLoading(true);
        await ServiceHelper.post("/api/courses/uploadcoursecertificate", values, (status, result) => {
            if (status === 200) {
                close();
                props.getData(props.programId);
            }
        }).finally(() => setLoading(false));
    };

    function close() {
        setOpenModal(false);
        setSelectedCourseId("");
        form.resetFields();
        setLoading(false);
    }

    function renderCourseStatusItem(item) {
        if (props.isUser) {
            if (item.status === 0) {
                return <Tooltip title={t('locked')}><LockOutlined /></Tooltip>
            } else if (item.status === 1) {
                return <Space>
                    <Button type='link' size='small' disabled={loading} onClick={() => preCompleteCourse(item.id, item.certificateMandatory)}>{t('complete')}</Button>
                    <Typography.Link href={item.url} target="_blank">{t('view')}</Typography.Link>
                </Space>
            } else if (item.status === 2) {
                return <Tag color="processing" icon={<SyncOutlined spin />}>{t('mentor_approval_awaited')}</Tag>
            }
            else if (item.status === 3) {
                return <Tag color="green" icon={<CheckCircleOutlined />}>{t('completed')}</Tag>
            }
            else if (item.status === 4) {
                return <Tag color="error" icon={<CloseCircleOutlined />}>{t('rejected')}</Tag>
            }
        } else {
            return <Typography.Link href={item.url} target="_blank">{t('view')}</Typography.Link>
        }
    }

    return (
        <>
            <List
                header={<Typography.Text strong>{t(props.title)}</Typography.Text>}
                bordered
                className={`course-list ${props.className}`}
                dataSource={props.data}
                renderItem={(item) => (
                    <List.Item className='d-flex justify-content-between'>
                        {item.title}
                        {renderCourseStatusItem(item)}
                        {/* {item.url
                            ? <Space>
                                <Button type='link' size='small' disabled={loading} onClick={() => preCompleteCourse(item.id, item.certificateMandatory)}>{t('complete')}</Button>
                                <Typography.Link href={item.url} target="_blank">{t('view')}</Typography.Link>
                            </Space>
                            : props.isUser && <Tooltip title={t('locked')}><LockOutlined /></Tooltip>} */}
                    </List.Item>
                )}
            />
            {
                props.data.length > 0 && <Modal title={t('upload_certificate')} open={openModal} okText={t('upload')} onOk={form.submit} confirmLoading={loading} onCancel={close}>
                    <Alert message={t('upload_certificate_desc')} className="mb-10 mt-20" />
                    <KozaForm form={form} onFinish={onFinish} showButtonGroup={false}>
                        <KozaFormItem name='certificateUrl' label='url_address' type="url" rules={[{ required: true, type: "url" }]} />
                    </KozaForm>
                </Modal>
            }
        </>
    );
};

CourseList.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    programId: PropTypes.string.isRequired,
    isUser: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default CourseList;