import React from 'react';
import './KozaCard.scss';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

const KozaCard = props => {
    const { t } = useTranslation();

    return (
        <Card id={props.id} className={props.classname} title={t(props.title)} bordered={false} extra={props.extra} loading={props.loading}>
            {props.children}
        </Card>
    );
};

KozaCard.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    extra: PropTypes.any,
    loading: PropTypes.bool,
    classname: PropTypes.string
};

export default KozaCard;