import React, { useEffect, useState } from 'react';
import './Profile.scss';
import { useTranslation } from 'react-i18next';
import { Tabs, Tag } from 'antd';
import Overview from './steps/Overview';
import Security from './steps/Security';
import Settings from './steps/Settings';
import KozaCard from '../../../components/koza-card/KozaCard';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import _ from "lodash";
import SocialMedia from './steps/SocialMedia';

const { TabPane } = Tabs;

const Profile = () => {

    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [tabKey, setTabKey] = useState("1");

    useEffect(() => {
        getData(tabKey);
    }, [])

    const getData = async (tabKey) => {
        setLoading(true);
        await ServiceHelper.get('/api/users/profile/getuserinfo', (status, result) => {
            if (status === 200) {
                setData(result);
                setTabKey(tabKey);
            }
        }).finally(() => setLoading(false));
    }

    function callback(key) {
        setTabKey(key);
    }


    const items = [
        {
            key: '1',
            label: t('overview'),
            children: <Overview />
        },
        {
            key: '2',
            label: t('settings'),
            children: <Settings data={data} aftersave={() => getData(tabKey)} />
        },
        {
            key: '3',
            label: t('security'),
            children: <Security />
        },
        {
            key: '4',
            label: t('social_media'),
            children: <SocialMedia data={data} aftersave={() => getData(tabKey)}/>
        },
    ];

    return (
        <div id='profile-container'>
            <KozaCard loading={isLoading}>
                {
                    !_.isEmpty(data) && <div className='d-flex flex-column'>
                        <div id='header' className='d-flex'>
                            <div>
                                <div className='mb-20 d-flex align-items-center'>
                                    <span id='full-name'>{data.name} {data.surname}</span>
                                    {
                                        data.roles.map((item, index) => <Tag key={index} color="magenta">{t(item)}</Tag>)
                                    }
                                </div>
                                {
                                    !_.isEmpty(data.email) && <div className='item d-flex align-items-center'>
                                        <i className="las la-envelope"></i>
                                        <a href={`mailto:${data.email}`}>{data.email}</a>
                                    </div>
                                }

                                {
                                    !_.isEmpty(data.userDetails.phone) && <div className='item d-flex align-items-center'>
                                        <i className="las la-phone"></i>
                                        <a href={`tel:${data.userDetails.phone}`}>{data.userDetails.phone}</a>
                                    </div>
                                }
                            </div>
                        </div>
                        <Tabs defaultActiveKey={tabKey} items={items} onChange={callback} className='mt-10' />
                    </div>
                }
            </KozaCard>
        </div>
    );
};

export default Profile;