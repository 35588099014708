import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, message, Row } from 'antd';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import KozaForm from '../../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../../components/koza-form-item/KozaFormItem';

const Security = props => {

    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        await ServiceHelper.post('/api/users/profile/changepasssword', values, (status, result) => {
            if (status === 200) {
                message.success(result);
            }
        }).finally(() => setLoading(false));
    }
    return (
        <KozaForm onFinish={onFinish} loading={isLoading}>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={16} xl={10}>
                    <KozaFormItem name='password' label='password' type="password" rules={[{ required: true }]} />
                    <KozaFormItem name='newPassword' label='new_password' type='password' rules={[{ required: true }, { pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})", message: "'Yeni Şifre' alanı en az 1 büyük, 1 küçük ve 1 özel karakter olacak şekilde en az 8 karakterli olmalıdır!" }]} />
                    <KozaFormItem name='rePassword' label='repassword' type='password' rules={[{ required: true }, { pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})", message: "'Şifre Tekrar' alanı en az 1 büyük, 1 küçük ve 1 özel karakter olacak şekilde en az 8 karakterli olmalıdır!" }]} />
                </Col>
            </Row>
        </KozaForm>

    );
};

Security.propTypes = {

};

export default Security;