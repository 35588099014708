import React, { useEffect, useState } from 'react';
import './KozaTransfer.scss';
import PropTypes from 'prop-types';
import { Transfer } from 'antd';

const KozaTransfer = props => {

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setTargetKeys(props.selectedData);
    }, []);

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
        props.setData(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (
        <Transfer
            locale={props.locale}
            dataSource={props.dataSource}
            titles={props.titles}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            showSearch
            pagination
            oneWay={props.oneWay}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
        />
    );
};

KozaTransfer.defaultProps = {
    oneWay: true
}

KozaTransfer.propTypes = {
    dataSource: PropTypes.array.isRequired,
    titles: PropTypes.array,
    oneWay: PropTypes.bool,
    setData: PropTypes.func,
    selectedData: PropTypes.array,
    locale: PropTypes.object
};

export default KozaTransfer;