import React from 'react';
import './KozaLangSelect.scss';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Helpers from '../../helpers/Helpers';

const { Option } = Select;

const KozaLangSelect = props => {

    const { _, i18n } = useTranslation();

    function handleChange(value) {
        localStorage.setItem("lang", value);
        i18n.changeLanguage(value);

        if (props && props.onChange) {
            props.onChange(value);
        }
    }

    return (
        <Select defaultValue={Helpers.getLang()} bordered={props.bordered} onChange={handleChange}>
            <Option value="tr">TR</Option>
            <Option value="en">EN</Option>
        </Select>
    );
};

KozaLangSelect.defaultProps = {
    bordered: true
};

KozaLangSelect.propTypes = {
    bordered: PropTypes.bool,
    onChange: PropTypes.func
};

export default KozaLangSelect;