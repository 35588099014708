import React, { useEffect, useState } from 'react';
import './Login.scss';
import { Button, Row } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import AuthContainer from '../../../components/auth-container/AuthContainer';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const Login = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        sessionStorage.removeItem("user");
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
        await ServiceHelper.post('/api/auth/login', values, (status, result) => {
            if (status === 200) {
                sessionStorage.setItem("user", JSON.stringify(result));
                navigate("/panel/dashboard");
            }
        }).finally(() => setLoading(false));
    };

    return (
        <AuthContainer image={`${process.env.PUBLIC_URL}/assets/img/auth/login.png`}>
            <div className='w-100 p-40'>
                <div className='mb-50'>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZA_ACIKZEMIN.svg`} alt='SİBERKOZA' id='logo2' />
                    <h2 className='text-center'> {t('auth_title')} 👋 </h2>
                    <p><CheckCircleTwoTone twoToneColor="#52c41a" /> {t('login_info_text1')}</p>
                    <p><CheckCircleTwoTone twoToneColor="#52c41a" /> {t('login_info_text2')}</p>
                    <p><CheckCircleTwoTone twoToneColor="#52c41a" /> {t('login_info_text3')}</p>
                </div>
                <KozaForm onFinish={onFinish} submitText="signin" showClearButton={false} block loading={isLoading}>
                    <KozaFormItem name='email' label='email_address' type='email' rules={[{ required: true, type: 'email' }]} />
                    <KozaFormItem name='password' label='password' type='password' rules={[{ required: true }, { pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})", message: t('password_pattern_error_message') }]} />
                    <Row justify='space-between' className='mb-10'>
                        <Button type='link' className='p-0' onClick={() => navigate("/auth/forgot-password")}>{t('forgot_password')}</Button>
                        <Button type='link' className='p-0' onClick={() => navigate("/auth/register")}>{t('signup')}</Button>
                    </Row>
                </KozaForm>
            </div>
        </AuthContainer>
    );
};

export default Login;