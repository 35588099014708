import React, { useEffect, useState } from 'react';
import './CourseLevelSelect.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const { Option } = Select;

const CourseLevelSelect = props => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await ServiceHelper.get('/api/advancedsettings/level/getall', (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <KozaFormItem name={props.name} label={props.label} type="select" loading={loading} rules={[{ required: true }]}>
            {
                !_.isEmpty(data) && data.map((item,index)=><Option key={index} value={item.id}>{t(item.name)}</Option>)
            }
        </KozaFormItem>
    );
};

CourseLevelSelect.defaultProps = {
    label: 'level'
}

CourseLevelSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default CourseLevelSelect;