import React, { useEffect, useState } from 'react';
import './ConfirmEmail.scss';
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContainer from '../../../components/auth-container/AuthContainer';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import _ from 'lodash';

const ConfirmEmail = () => {

    let navigate = useNavigate();
    let params = useParams();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isEmpty(params) && params["q"]) {
            confirmEmail(params["q"]);
        }
    }, []);

    async function confirmEmail(data) {
        await ServiceHelper.post("/api/auth/confirmemail", { data: data }, (status, result) => {
            if (result)
                message.success(result);
            navigate("/auth/login");
        })
    }

    return (
        <AuthContainer image={`${process.env.PUBLIC_URL}/assets/img/auth/login.png`}>
            {
                isLoading && <div className='text-center'>
                    <Spin size='large' />
                    <p>{t('confirm_email_message')}</p>
                </div>
            }
        </AuthContainer>
    );
};

export default ConfirmEmail;