import React, { useEffect, useState } from 'react';
import './Program.scss';
import { Button, Form, message, Space, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import KozaCard from '../../../components/koza-card/KozaCard';
import KozaForm from '../../../../components/koza-form/KozaForm';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const Program = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [cardTitle, setCardTitle] = useState("project_add");
    const [currentStep, setCurrentStep] = useState(0);
    const [stepStatus, setStepStatus] = useState("process");

    const [allCourses, setAllCourses] = useState([]);
    const [selectedPreCourses, setPreCourses] = useState([]);
    const [selectedCourses, setCourses] = useState([]);

    const [initialValues, setInitialValues] = useState({
        "isActive": true
    });

    useEffect(() => {
        if (!_.isEmpty(params) && params["id"]) {
            setCardTitle("project_update");
            getData(params.id);
        }
    }, []);

    const getData = async (id) => {
        setLoading(true);
        await ServiceHelper.get(`/api/programs/getbyid?id=${id}`, (status, result) => {
            if (status === 200) {
                setInitialValues(result);
                setPreCourses(result.preCourses);
                setCourses(result.courses);
            }
        }).finally(() => setLoading(false));
    }

    const save = async () => {

        if (_.isEmpty(selectedCourses)) {
            setStepStatus("error");
            message.error(t('validation_program_course'));
            return;
        }

        var data = initialValues;
        data["courses"] = selectedCourses;

        setLoading(true);

        if (!_.isEmpty(params) && params["id"]) {
            data["id"] = params["id"];
        }

        await ServiceHelper.post("/api/programs/save", data, (status, result) => {
            if (status === 200) {
                message.success(result);
                navigate('/panel/projects/list');
            }
        }).finally(() => setLoading(false));
    };

    const nextStep = () => {
        if (currentStep === 0) {
            form.submit();
        } else {
            if (stepStatus != "process")
                setStepStatus("process");

            var _initialValues = initialValues;

            if (currentStep === 1) {
                _initialValues["precourses"] = selectedPreCourses;
            } else if (currentStep === 2) {

            }

            setInitialValues(_initialValues);

            var nextStep = currentStep + 1;
            if (nextStep < steps.length)
                setCurrentStep(nextStep);
        }
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const onFinish = (values) => {
        if (stepStatus != "process")
            setStepStatus("process");

        if (!_.isEmpty(values["assistantMentors"])) {
            var intersect = _.intersection(values["mentors"], values["assistantMentors"]);
            if (!_.isEmpty(intersect)) {
                message.warning(t('validation_same_mentor_assistantmentor'));
                return;
            }
        }

        setInitialValues(values);
        setCurrentStep(currentStep + 1);
    }

    const onFinishFailed = (e) => {
        if (e && e.errorFields.length > 0) {
            setStepStatus("error");
        }
    }

    const steps = [
        {
            title: t('general_information'),
            content: <Step1 />,
        },
        {
            title: t('prerequisite_courses'),
            content: <Step2 selectedData={selectedPreCourses} setPreCourses={setPreCourses} setAllCourses={setAllCourses} />,
        },
        {
            title: t('project_courses'),
            content: <Step3 allCourses={allCourses} preCourses={selectedPreCourses} selectedData={selectedCourses} setCourses={setCourses} />,
        }
    ];

    return (
        <KozaCard title={cardTitle} id="program-container" loading={loading}>
            <Steps current={currentStep} items={steps} status={stepStatus} />

            <KozaForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues} showButtonGroup={false}>
                {
                    currentStep < steps.length && <div className="steps-content">{steps[currentStep].content}</div>
                }
            </KozaForm>

            <div className="steps-action">
                <Space className='float-right'>
                    {currentStep > 0 && (
                        <Button onClick={() => prevStep()}> {t("previous")} </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={() => nextStep()}> {t("next")} </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={() => save()} loading={loading}> {t('save')} </Button>
                    )}
                </Space>
            </div>
        </KozaCard>
    );
};

export default Program;