import axios from 'axios';
import Helper from './Helpers';
import { message } from 'antd';

class ServiceHelper {
    async post(url, data, callback) {
        await axios.post(Helper.getEndPoint() + url, data, {
            headers: Helper.getAuthHeader()
        }).then(response => {
            return callback(response.status, response.data);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 500) {
                    message.error(error.response.data.Message ?? "Sunucu taraflı bilinmeyen bir hata oluştu!");
                }
                else if (error.response.status === 404) {
                    message.error(error.response.data.Message ?? "Aradığınız sayfa bulunamadı!");
                }
                else if (error.response.status === 400) {
                    if (error.response.data.title) {
                        message.error(error.response.data.title);
                    } else if (error.response.data) {
                        message.error(error.response.data)
                    } else {
                        message.error("İşleminiz gerçekleşirken hata oluştu!");
                    }
                }
                else if (error.response.status === 401) {
                    message.warning("Oturumunuz sonlandırılmıştır!");
                    window.location.replace("/");
                }
                else if (error.response.status === 403) {
                    message.warning("Oturumunuz sonlandırılmıştır!");
                    window.location.replace("/");
                }
                else if (error.response.status === 0) {
                    message.error(error.message);
                }
                else {
                    message.error(error.response.data);
                }
                console.log(error.message);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
                message.error(error.message);
            } else {
                console.log('Error', error.message);
            }
            console.log(error);
            return callback(error.status);
        });
    }

    async get(url, callback) {
        await axios.get(Helper.getEndPoint() + url, {
            headers: Helper.getAuthHeader()
        }).then(response => {
            return callback(response.status, response.data);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log(error.response)
                    console.log(error.response.headers)
                    message.warning("Oturumunuz sonlandırılmıştır!");
                    window.location.replace("/");
                }
                else if (error.response.status === 403) {
                    message.warning("Oturumunuz sonlandırılmıştır!");
                    window.location.replace("/");
                }
                else if (error.response.status === 400) {
                    message.warning(error.response.data);
                }
                else if (error.response.status === 404 || error.response.status === 0) {
                    message.error(error.message);
                    setTimeout(() => {
                        window.location.replace("/");
                    }, 1500);
                }

                console.log(error.message);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
            return callback(error.status);
        });
    }

}
export default new ServiceHelper();