import React, { useEffect, useState } from 'react';
import './Users.scss';
import { Avatar, Button, Modal, Tooltip, Select, Form, message, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import KozaCard from '../../../components/koza-card/KozaCard';
import KozaTable from '../../../components/koza-table/KozaTable';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import { useTranslation } from 'react-i18next';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import Constants from '../../../../helpers/Constants';

const { Option } = Select;

const Users = () => {

    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [isLoadingforRoles, setLoadingforRoles] = useState(false);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get('/api/users/getall', (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const getRoles = async () => {
        setLoadingforRoles(true);
        await ServiceHelper.get('/api/roles/getall', (status, result) => {
            if (status === 200) {
                setRoles(result);
            }
        }).finally(() => setLoadingforRoles(false));
    }

    const onFinish = async (values) => {
        var data = {
            id: selectedUserId,
            roles: values["roleName"]
        }
        setLoadingforRoles(true);
        await ServiceHelper.post("/api/users/updateuserrole", data, (status, result) => {
            if (status === 200) {
                message.success(result);
                getData();
                setShowModal(false);
                setSelectedUserId(0);
                setRoles([]);
                form.resetFields();
            }
        }).finally(() => setLoadingforRoles(false));
    }

    function renderRoleTag(role, index) {
        let color = "green";
        if (role === Constants.ROLE_ADMIN || role == Constants.ROLE_SUPERADMIN) {
            color = "volcano"
        } else if (role === Constants.ROLE_STUDENT) {
            color = "geekblue";
        }
        return <Tag key={index} color={color}>{t(role)}</Tag>
    }

    const columns = [
        {
            title: '',
            dataIndex: 'profileImage',
            key: 'profileImage',
            render: (item, record) => {
                return item
                    ? <Avatar src={item} />
                    : <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{record.name.charAt(0)}</Avatar>
            }
        },
        {
            title: t('first_name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: t('last_name'),
            dataIndex: 'surname',
            key: 'surname',
            sorter: (a, b) => a.surname.localeCompare(b.surname)
        },
        {
            title: t('email_address'),
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: t('role'),
            dataIndex: 'roles',
            key: 'roles',
            render: item => item.map((role, index) => renderRoleTag(role, index))
        },
        {
            title: t('action'),
            dataIndex: 'id',
            key: 'id',
            align: 'right',
            render: item => <Tooltip key={item.id} title={t('update_role')}>
                <Button type="primary" shape="circle" icon={<i className="las la-user-cog" />} size="small" onClick={() => {
                    setSelectedUserId(item);
                    getRoles();
                    setShowModal(true);
                }} />
            </Tooltip>

        },
    ];

    return (
        <>
            <KozaCard title='user_list' loading={isLoading} extra={
                <Tooltip title={t('refresh')}>
                    <Button shape="circle" icon={<ReloadOutlined />} onClick={() => getData()} />
                </Tooltip>}>
                <KozaTable dataSource={data} columns={columns} isLoading={isLoading} size="small" />
            </KozaCard>
            <Modal title={t('update_user_role')} open={showModal} confirmLoading={isLoadingforRoles} okText={t("update")} onOk={() => form.submit()} onCancel={() => {
                setShowModal(false);
                setSelectedUserId(0);
                setRoles([]);
            }}>
                <KozaForm form={form} onFinish={onFinish} showSubmitButton={false} showClearButton={false} isLoading={isLoadingforRoles}>
                    <KozaFormItem name='roleName' label={t('user_role')} type='select' mode="multiple" isLoading={isLoadingforRoles} rules={[{ required: true }]}>
                        {
                            roles.map((item, index) => <Option key={index} value={item.name}>{t(item.name)}</Option>)
                        }
                    </KozaFormItem>
                </KozaForm>
            </Modal>
        </>
    );
};

export default Users;