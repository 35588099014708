import React from 'react';
import PropTypes from 'prop-types';
import KozaFormItem from '../../../../../components/koza-form-item/KozaFormItem';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../../../components/custom/language-select/LanguageSelect';
import EducationCategoryCascader from '../../../../components/custom/education-category-cascader/EducationCategoryCascader';
import CourseLevelSelect from '../../../../components/custom/course-level-select/CourseLevelSelect';
import UsersSelect from '../../../../components/custom/users-select/UsersSelect';
import Constants from '../../../../../helpers/Constants';

const Step1 = props => {

    return (
        <Row gutter={16}>
            <Col span={24}>
                <KozaFormItem name='isActive' label='status' type="switch" checkedchildren="active" uncheckedchildren="passive" />
            </Col>
            <Col span={18}>
                <KozaFormItem name='title' label='project_name' rules={[{ required: true, min: 5, max: 150 }]} />
            </Col>
            <Col span={6}>
                <LanguageSelect name='languageIds' mode="multiple" />
            </Col>
            <Col span={8}>
                <EducationCategoryCascader name='categoryIds' label="category" addNone={false} />
            </Col>
            <Col span={8}>
                <CourseLevelSelect name='levelId' />
            </Col>
            <Col span={8}>
                {/* <KozaFormItem name='image' label='cover_photo' type="file" accept="image/*" rules={[{ required: true }]} /> */}
            </Col>
            <Col span={12}>
                <UsersSelect name="mentors" label="mentors" role={Constants.ROLE_MENTOR} mode="multiple" />
            </Col>
            <Col span={12}>
                <UsersSelect name="assistantMentors" label="assistant_mentors" role={Constants.ROLE_ASSISTANT_MENTOR} mode="multiple" />
            </Col>
            <Col span={24}>
                <KozaFormItem name='description' label='description' type="textarea" maxLength={1500} rules={[{ required: true, max: 1500 }]} />
            </Col>
        </Row>
    );
};

Step1.propTypes = {

};

export default Step1;