import React, { useEffect, useState } from 'react';
import './ForgotPassword.scss';
import _ from 'lodash';
import { Button, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContainer from '../../../components/auth-container/AuthContainer';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const ForgotPassword = () => {

    let navigate = useNavigate();
    let params = useParams();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(params) && params["q"]) {
            setIsChangePassword(true);
        }
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
        var url = '/api/auth/forgotpassword';
        if (isChangePassword) {
            url = '/api/auth/resetpassword';
            values["data"] = params["q"];
        }
        await ServiceHelper.post(url, values, (status, result) => {
            if (status === 200) {
                message.success(result);
                navigate("/auth/login");
            }
        }).finally(() => setLoading(false));
    };

    return (
        <AuthContainer image={`${process.env.PUBLIC_URL}/assets/img/auth/login.png`}>
            <div className='w-100 p-40'>
                <div className='mb-50'>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZA_ACIKZEMIN.svg`} alt='SİBERKOZA' id='logo2' />
                    <h2 className='text-center'> {t('reset_password')} </h2>
                    {!isChangePassword && <p>{t('password_reset_description')}</p>}
                </div>
                <KozaForm onFinish={onFinish} submitText={t(isChangePassword ? "reset_password" : "send_email")} showClearButton={false} loading={isLoading} block>
                    {
                        isChangePassword
                            ? <>
                                <KozaFormItem name='newpassword' label='password' type='password' rules={[{ required: true }, { pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})", message: t('password_pattern_error_message') }]} />
                                <KozaFormItem name='repassword' label='repassword' type='password' rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newpassword') === value)
                                                return Promise.resolve();
                                            return Promise.reject(new Error(t("password_and_repassword_match_error_message")));

                                        }
                                    })
                                ]} />
                            </>
                            : <KozaFormItem name='email' label='email_address' type='email' rules={[{ required: true, type: 'email', max: 50 }]} />
                    }

                    <Row justify='end' className='mb-10'>
                        <Button type='link' className='p-0' onClick={() => navigate("/auth/login")}>{t('signin')}</Button>
                    </Row>
                </KozaForm>
            </div>
        </AuthContainer>
    );
};

export default ForgotPassword;