import React from 'react';
import './Page404.scss';

const Page404 = () => {
    return (
        <div>
            Page 404
        </div>
    );
};

export default Page404;