import React, { useEffect, useState } from 'react';
import './EducationCategoryCascader.scss'
import PropTypes from 'prop-types';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const EducationCategoryCascader = props => {

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        var url = '/api/advancedsettings/category/getallforcascader';
        if (!props.addNone)
            url += '?addNone=false';

        await ServiceHelper.get(url, (status, result) => {
            if (status === 200) {
                setOptions(result);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <KozaFormItem name={props.name} label={props.label} type="cascader" options={options}
            changeOnSelect={true} loading={loading} rules={[{ required: true }]} />
    );
};

EducationCategoryCascader.defaultProps = {
    label: 'parent_category',
    addNone: true
}

EducationCategoryCascader.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    addNone: PropTypes.bool
};

export default EducationCategoryCascader;