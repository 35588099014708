import React from 'react';
import './DeletePopconfirm.scss';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

const DeletePopconfirm = props => {
    const { t } = useTranslation();

    return (
        <Popconfirm title={t("popconfirm_delete_question")} onConfirm={props.onConfirm} okText={t("delete")} cancelText={t("cancel")}>
            <a>{t("delete")}</a>
        </Popconfirm>
    );
};

DeletePopconfirm.propTypes = {
    onConfirm: PropTypes.func.isRequired
};

export default DeletePopconfirm;