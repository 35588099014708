import React from 'react';
import './KozaForm.scss';
import PropTypes from 'prop-types';
import { Button, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const KozaForm = props => {

    const { t } = useTranslation();

    const typeTemplate = "'${name}' is not a valid ${type}";
    const defaultValidateMessages = {
        default: t('input_validation_default'),
        required: t('input_validation_required'),
        enum: "'${name}' must be one of [${enum}]",
        whitespace: "'${name}' cannot be empty",
        date: {
            format: "'${name}' is invalid for format date",
            parse: "'${name}' could not be parsed as date",
            invalid: "'${name}' is invalid date",
        },
        types: {
            string: typeTemplate,
            method: typeTemplate,
            array: typeTemplate,
            object: typeTemplate,
            number: typeTemplate,
            date: typeTemplate,
            boolean: typeTemplate,
            integer: typeTemplate,
            float: typeTemplate,
            regexp: typeTemplate,
            email: t('input_validation_types_email'),
            url: t('input_validation_types_url'),
            hex: typeTemplate,
        },
        string: {
            len: "'${label}' must be exactly ${len} characters",
            min: t('input_validation_string_min'),
            max: t('input_validation_string_max'),
            range: t('input_validation_string_range'),
        },
        number: {
            len: "'${name}' must equal ${len}",
            min: "'${name}' cannot be less than ${min}",
            max: "'${name}' cannot be greater than ${max}",
            range: "'${name}' must be between ${min} and ${max}",
        },
        array: {
            len: "'${name}' must be exactly ${len} in length",
            min: "'${name}' cannot be less than ${min} in length",
            max: "'${name}' cannot be greater than ${max} in length",
            range: "'${name}' must be between ${min} and ${max} in length",
        },
        pattern: {
            mismatch: "'${name}' does not match pattern ${pattern}",
        },
    };

    return (
        <Form
            layout='vertical'
            autoComplete="off"
            validateMessages={props.validationRules || defaultValidateMessages}
            onFinish={props.onFinish}
            form={props.form}
            initialValues={props.initialValues}
            onFinishFailed={props.onFinishFailed}
        >
            {props.children}
            {
                props.showButtonGroup && <Row justify='end' className='button-group'>
                    {props.showClearButton && <Button type="ghost" htmlType="reset">{t('clear')}</Button>}
                    {props.showSubmitButton && <Button type="primary" htmlType="submit" block={props.block} loading={props.loading}>{t(props.submitText || 'save')}</Button>}
                </Row>
            }
        </Form>
    );
};

KozaForm.defaultProps = {
    showClearButton: true,
    showSubmitButton: true,
    showButtonGroup: true,
    block: false,
    initialValues: {}
}

KozaForm.propTypes = {
    submitText: PropTypes.string,
    showClearButton: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    showButtonGroup: PropTypes.bool,
    validationRules: PropTypes.object,
    block: PropTypes.bool,
    onFinish: PropTypes.func.isRequired,
    onFinishFailed: PropTypes.func,
    loading: PropTypes.bool,
    form: PropTypes.any,
    initialValues: PropTypes.object
};

export default KozaForm;