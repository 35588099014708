import React, { useEffect, useState } from 'react';
import './PendingUsers.scss';
import PropTypes from 'prop-types';
import KozaCard from '../../../../../../components/koza-card/KozaCard';
import { useTranslation } from 'react-i18next';
import { Alert, Button, message, Space, Tooltip, Typography } from 'antd';
import ServiceHelper from '../../../../../../../helpers/ServiceHelper';
import KozaTable from '../../../../../../components/koza-table/KozaTable';
import { ReloadOutlined } from '@ant-design/icons';

const { Link } = Typography;

const PendingUsers = props => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.programId) {
            getData();
        }
    }, []);

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get(`/api/programs/getpendingstudents?programId=${props.programId}`, (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const confirmUser = async (userId, type, approveAll) => {
        var model = {
            userId: userId,
            programId: props.programId,
            type: type === "approve" || approveAll ? 1 : 0,
            approveAll: approveAll
        }

        setApproveLoading(true);
        await ServiceHelper.post('/api/programs/changeuserprogramstatus', model, (status, result) => {
            if (status === 200) {
                message.success(result);
                getData();
            }
        }).finally(() => setApproveLoading(false));
    }

    const columns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('surname'),
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title: t('action'),
            key: '',
            align: "right",
            render: (text, record) => (
                record.status !== 2 && <Space size="middle">
                    <Link onClick={() => confirmUser(record.id, "approve", false)}>{t('approve')}</Link>
                    <Link onClick={() => confirmUser(record.id, "reject", false)} type='danger'> {t('reject')}</Link>
                </Space>
            ),
        },
    ];

    return (
        <KozaCard title={`${t('program_details_awaiting_students')} (${t('project')})`} loading={loading} classname="mb-10" extra={<Space>
            {!loading && data.length > 0 && <Button size='small' type='primary' onClick={() => confirmUser("", "", true)}>{t('approve_all')}</Button>}
            <Tooltip title={t('refresh')}>
                <Button size='small' shape="circle" icon={<ReloadOutlined />} onClick={getData} />
            </Tooltip>
        </Space>
        }>
            {data.length > 0 && <Alert message={t('program_details_awaiting_students_desc')} className="mb-20" />}
            <KozaTable dataSource={data} columns={columns} size="small" isLoading={approveLoading} />
        </KozaCard>
    );
};

PendingUsers.propTypes = {
    programId: PropTypes.string
};

export default PendingUsers;