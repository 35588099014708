import React, { useState } from 'react';
import './Events.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KozaCard from '../../../../components/koza-card/KozaCard';
import KozaTable from '../../../../components/koza-table/KozaTable';

const Events = props => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    const columns = [];

    return (
        <KozaCard title={t('events')} classname="mb-20">
            <KozaTable dataSource={data} columns={columns} />
        </KozaCard>
    );
};

Events.propTypes = {
    
};

export default Events;