import React from 'react';
import './KozaFormItem.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Form, Cascader, Switch, Select, InputNumber, Radio, Space, DatePicker } from 'antd';

import Helpers from '../../helpers/Helpers'
import moment from 'moment';

const KozaFormItem = props => {

    const { t } = useTranslation();

    const renderInput = (type) => {
        switch (type) {
            case "textarea":
                return <Input.TextArea rows={4} showCount maxLength={props.maxLength} />;
            case "cascader":
                return <Cascader options={props.options} loading={props.loading} changeOnSelect={props.changeOnSelect} />
            // case "password":
            //     return <Input.Password />
            case "number":
                return <InputNumber min={props.min} max={props.max} className="w-100" />
            case "select":
                return <Select
                    mode={props.mode}
                    // placeholder={t(props.placeholder)}
                    // value={props.value}
                    // defaultValue={props.defaultValue}
                    // onChange={props.onchange}
                    // style={{ width: '100%' }}
                    loading={props.loading}
                    allowClear>
                    {props.children}
                </Select>
            // case "radiogroup":
            //     return <Radio.Group onChange={props.onChange}>
            //         <Space direction={props.direction}>
            //             {props.children}
            //         </Space>
            //     </Radio.Group>
            case "file":
                return <Input type={props.type} placeholder={t(props.placeholder)} accept={props.accept} onChange={props.onchange} />;
            // case "datepicker":
            //     return <DatePicker className='w-100' defaultValue={props.defaultValue} />
            case "date":
                return <Input type='date' defaultValue={moment(props.defaultValue).format("YYYY-MM-DD")} />
            default:
                return <Input type={props.type} placeholder={t(props.placeholder)} value={props.value} />;
        }
    }

    return (
        props.type != "switch"
            ? <Form.Item label={t(props.label)} name={props.name} rules={props.rules} className={props.type === "hidden" && "d-none"}>
                {renderInput(props.type)}
            </Form.Item>
            : <div className={`d-flex ${Helpers.prefixCls}-switch-container`}>
                {!props.switchlabelright && <label className='mr-10'>{t(props.label)}</label>}
                <Form.Item name={props.name} valuePropName="checked">
                    <Switch
                        checkedChildren={t(props.checkedchildren)}
                        unCheckedChildren={t(props.uncheckedchildren)} />
                </Form.Item>
                {props.switchlabelright && <label className='ml-10'>{t(props.label)}</label>}
            </div>
    );
};

KozaFormItem.defaultProps = {
    type: "text",
    rules: [],
    options: [],
    placeholder: "",
    defaultValue: [],
    switchlabelright: false
};

KozaFormItem.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    rules: PropTypes.array,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    checkedchildren: PropTypes.string,
    uncheckedchildren: PropTypes.string,
    maxLength: PropTypes.number,
    accept: PropTypes.string,
    mode: PropTypes.string,
    value: PropTypes.any,
    onchange: PropTypes.func,
    changeOnSelect: PropTypes.bool,
    loading: PropTypes.bool,
    switchlabelright: PropTypes.bool
};

export default KozaFormItem;