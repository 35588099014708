import React, { useEffect, useState } from 'react';
import './Courses.scss';
import KozaCard from "../../../components/koza-card/KozaCard"
import KozaTable from '../../../components/koza-table/KozaTable';
import { Space, Badge, Tag, Dropdown, Button, Popconfirm, Tooltip, message } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TableActionButtons from '../../../components/table-action-buttons/TableActionButtons';
import DeletePopconfirm from '../../../components/delete-popconfirm/DeletePopconfirm';
import { Link, useNavigate } from 'react-router-dom';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const Courses = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [cardTitle, setCardTitle] = useState("course_list");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get('/api/courses/getallforlist', (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const handleDeleteItem = async (id) => {
        setLoading(true)
        await ServiceHelper.post('/api/courses/delete', { id: id }, (status, result) => {
            if (status === 200) {
                if (status === 200) {
                    message.success(result);
                    getData();
                }
            }
        }).finally(() => setLoading(false));
    }

    const handleEditItem = (e) => {
    }

    function renderStatusTag(status) {
        switch (status) {
            case 1:
                return <Badge status='error' text={t('passive')} />
            case 2:
                return <Tag color='magenta'>{t('deleted')}</Tag>
            default:
                return <Badge status='success' text={t('active')} />
        }
    }

    const columns = [
        {
            title: t('status'),
            dataIndex: 'status',
            render: (item) => renderStatusTag(item)
        },
        {
            title: t('course_name'),
            dataIndex: 'title',
        },
        {
            title: t('category'),
            dataIndex: 'categories',
        },
        {
            title: t('level'),
            dataIndex: 'level',
            render: (item) => {
                var color = "green";
                switch (item) {
                    case "Intermediate":
                        color = "geekblue";
                        break;

                    case "Advanced":
                        color = "volcano";
                        break;
                }
                return <Tag color={color}>{item}</Tag>
            }
        },
        {
            title: t('training_provider'),
            dataIndex: 'trainingProvider'
        },
        {
            title: '',
            align: "right",
            render: (_, record) => (
                record.status !== 2 && <Space size="middle">
                    <DeletePopconfirm onConfirm={() => handleDeleteItem(record.id)} />
                    <Link to={`/panel/courses/edit/${record.id}`}>{t("edit")}</Link>
                </Space>
            )
        },
    ];

    return (
        <KozaCard title={cardTitle} extra={<Space>
            <Tooltip title={t('refresh')}>
                <Button shape="circle" icon={<SyncOutlined />} onClick={() => getData()} />
            </Tooltip>
            <Button icon={<PlusOutlined />} onClick={() => navigate("/panel/courses/add")}>{t('add')}</Button>
        </Space>}>
            <KozaTable columns={columns} dataSource={data} loading={loading} />
        </KozaCard>
    );
};

export default Courses;