import React, { useState } from 'react';
import './Register.scss';
import { Button, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import AuthContainer from '../../../components/auth-container/AuthContainer';
import ServiceHelper from '../../../../helpers/ServiceHelper';

const Register = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        await ServiceHelper.post("/api/auth/register", values, (status, result) => {
            if (status === 200) {
                message.success(result);
                navigate("/auth/login");
            }
        }).finally(() => setLoading(false));
    };

    return (
        <AuthContainer image={`${process.env.PUBLIC_URL}/assets/img/auth/login.png`}>
            <div className='w-100 p-40'>
                <div className='mb-20'>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZA_ACIKZEMIN.svg`} alt='SİBERKOZA' id='logo2' />
                    <h2 className='text-center'> {t('signup_siber_koza')} 👋 </h2>
                </div>
                <KozaForm onFinish={onFinish} submitText="signup" showClearButton={false} loading={isLoading} block>
                    <KozaFormItem name='name' label='name' rules={[{ required: true, min: 3, max: 15 }]} />
                    <KozaFormItem name='surname' label='surname' rules={[{ required: true, min: 3, max: 15 }]} />
                    <KozaFormItem name='email' label='email_address' type='email' rules={[{ required: true, type: 'email' }]} />
                    <KozaFormItem name='password' label='password' type='password' rules={[{ required: true }, { pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})", message: t('password_pattern_error_message') }]} />

                    <Row justify='end' className='mb-10'>
                        <Button type='link' className='p-0' onClick={() => navigate("/auth/login")}>{t('signin')}</Button>
                    </Row>
                </KozaForm>
            </div>
        </AuthContainer>
    );
};

export default Register;