import React, { useEffect, useState } from 'react';
import './Course.scss';
import _ from "lodash";
import { Col, Form, Input, message, Row, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import KozaCard from '../../../components/koza-card/KozaCard';
import KozaForm from '../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../components/koza-form-item/KozaFormItem';
import EducationCategoryCascader from '../../../components/custom/education-category-cascader/EducationCategoryCascader';
import CourseLevelSelect from '../../../components/custom/course-level-select/CourseLevelSelect';
import TrainingProviderSelect from '../../../components/custom/training-provider-select/TrainingProviderSelect';
import LanguageSelect from '../../../components/custom/language-select/LanguageSelect';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import { useTranslation } from 'react-i18next';

const Course = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [cardTitle, setCardTitle] = useState("add_new_course");
    const [initialValues, setInitialValues] = useState({
        "isActive": true,
        "certificateMandatory": true,
        "_startDate": "",
        "_endDate": ""
    });

    useEffect(() => {
        if (!_.isEmpty(params)) {
            var id = params["id"];
            if (id) {
                setCardTitle("course_update");
                getData(id);
            }
        }
    }, []);

    const getData = async (id) => {
        setLoading(true);
        await ServiceHelper.get(`/api/courses/getbyid?id=${id}`, (status, result) => {
            if (status === 200) {
                result["_startDate"] = result["startDate"];
                result["_endDate"] = result["endDate"];
                delete result.startDate;
                delete result.endDate;
                setInitialValues(result);
            }
        }).finally(() => setLoading(false));
    }

    const handleSave = async (data) => {
        setSaveLoading(true);
        await ServiceHelper.post("/api/courses/save", data, (status, result) => {
            if (status === 200) {
                message.success(result);
                navigate("/panel/courses/list");
            }
        }).finally(() => setSaveLoading(false));
    }


    return (
        <KozaCard title={cardTitle} loading={loading}>
            <KozaForm onFinish={handleSave} initialValues={initialValues} isLoading={saveLoading}>
                <KozaFormItem name='id' type="hidden" />
                <Row gutter={16}>
                    <Col span={24}>
                        <KozaFormItem name='isActive' label='status' type="switch" checkedchildren="active" uncheckedchildren="passive" />
                    </Col>
                    <Col span={20}>
                        <KozaFormItem name='title' label='course_name' rules={[{ required: true }]} />
                    </Col>
                    <Col span={4}>
                        <LanguageSelect name='languageId' />
                    </Col>
                    <Col span={8}>
                        <EducationCategoryCascader name='categoryIds' label="category" addNone={false} />
                    </Col>
                    <Col span={8}>
                        <CourseLevelSelect name="levelId" />
                    </Col>
                    {/* <Col span={8}>
                        <KozaFormItem name='image' label='cover_photo' type="file" accept="image/*" rules={[{ required: true }]} />
                    </Col> */}
                    <Col span={8}>
                        <TrainingProviderSelect name='trainingProviderId' />
                    </Col>
                    <Col span={4}>
                        <KozaFormItem name='time' label='time_hour' type="number" min={0} rules={[{ required: true }]} />
                    </Col>
                    <Col span={10}>
                        <KozaFormItem name='startDate' label="start_date" type="date" defaultValue={initialValues["_startDate"]} />
                    </Col>
                    <Col span={10}>
                        <KozaFormItem name='endDate' label="end_date" type="date" defaultValue={initialValues["_endDate"]} />
                    </Col>
                    <Col span={24}>
                        <KozaFormItem name='url' label='url_address' type="url" rules={[{ required: true, type: "url" }]} />
                    </Col>
                    <Col span={24}>
                        <KozaFormItem name='description' label='description' type="textarea" maxLength={500} />
                    </Col>
                    <Col span={12}>
                        <KozaFormItem name='certificateMandatory' label='certificate_mandatory' type="switch" checkedchildren="yes" uncheckedchildren="no" />
                    </Col>
                </Row>
            </KozaForm>
        </KozaCard>
    );
};


export default Course;