import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, message, Row } from 'antd';
import KozaForm from '../../../../../components/koza-form/KozaForm';
import KozaFormItem from '../../../../../components/koza-form-item/KozaFormItem';
import ServiceHelper from '../../../../../helpers/ServiceHelper';

const Settings = props => {

    var data = props.data.userDetails;
    data["name"] = props.data.name;
    data["surname"] = props.data.surname;

    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        await ServiceHelper.post('/api/users/profile/saveuserdetails', values, (status, result) => {
            if (status === 200) {
                message.success(result);
                props.aftersave();
            }
        }).finally(() => setLoading(false));
    }

    return (
        <KozaForm onFinish={onFinish} loading={isLoading} initialValues={data}>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                    <KozaFormItem name='name' label='name' />
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                    <KozaFormItem name='surname' label='surname' />
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={12}>
                    <KozaFormItem name='phone' label='phone_number' type='phone' />
                </Col>
            </Row>
        </KozaForm>
    );
};

Settings.propTypes = {
    data: PropTypes.object.isRequired,
    aftersave: PropTypes.func
};

export default Settings;