import React from 'react';
import './KozaConfirm.scss';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';

const KozaConfirm = props => {
    return (
        <Popconfirm title={props.title} onConfirm={props.onconfirm} okText={props.oktext} cancelText={props.canceltext} placement={props.placement} okButtonProps={{ loading: props.loading }}>
            {props.children}
        </Popconfirm>
    );
};

KozaConfirm.defaultProps = {
    placement: "topRight"
};

KozaConfirm.propTypes = {
    title: PropTypes.string.isRequired,
    onconfirm: PropTypes.func,
    oktext: PropTypes.string,
    canceltext: PropTypes.string,
    placement: PropTypes.string,
    loading: PropTypes.bool
};

export default KozaConfirm;