import React from 'react';
import './TableActionButtons.scss';
import PropTypes from 'prop-types';
import { Dropdown, Popconfirm, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const TableActionButtons = props => {
    const { t } = useTranslation();

    return (
        <Space size="middle" wrap>
            <Dropdown.Button menu={props.menuProps} trigger={["click"]} onClick={!props.isPopConfirm ? props.buttonClick : null}>
                {
                    props.isPopConfirm
                        ? <Popconfirm title={t("popconfirm_delete_question")} onConfirm={props.buttonClick} okText={t("delete")} cancelText={t("cancel")}>
                            <a href='#' className='d-block'>{t(props.text)}</a>
                        </Popconfirm>
                        : t(props.text)
                }
            </Dropdown.Button>
        </Space>
    );
};

TableActionButtons.defaultProps = {
    isPopConfirm: false
}

TableActionButtons.propTypes = {
    menuProps: PropTypes.object.isRequired,
    buttonClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    isPopConfirm: PropTypes.bool
};

export default TableActionButtons;