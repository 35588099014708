import jwtDecode from "jwt-decode";
import _ from "lodash";
import Constants from './Constants';

class Helpers {

    prefixCls = "koza";
    isDebug = false;

    getEndPoint() {
        return this.isDebug ? "https://localhost:7034" : "https://api.siberkoza.com";
    }

    getLang() {
        var lang = localStorage.getItem("lang");
        if (lang)
            return lang;

        localStorage.setItem("lang", "tr");
        return "tr";
    }

    getAuthHeader() {
        var storageData = this.getSessionStorageData("user");
        return {
            'Authorization': `bearer ${storageData !== null ? storageData.token : ""}`,
            'Accept-Language': this.getLang()
        };
    }

    isAuthenticate() {
        return this.getSessionStorageData("user") === null ? false : true;
    }

    getLocalStorageData(name) {
        var localStorageData = localStorage.getItem(name);
        if (localStorageData) {
            return JSON.parse(localStorageData);
        }
        else {
            return null;
        }
    }

    setLocalStorageData(name, data) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    getSessionStorageData(name) {
        var sessionStorageData = sessionStorage.getItem(name);
        if (sessionStorageData) {
            return JSON.parse(sessionStorageData);
        }
        else {
            return null;
        }
    }

    getUserRole() {
        var storage = this.getSessionStorageData("user");
        if (storage) {
            var decode = jwtDecode(storage.token);
            if (!_.isEmpty(decode)) {
                return decode["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            }
        }
    }

    getUserName() {
        var storage = this.getSessionStorageData("user");
        if (storage) {
            var decode = jwtDecode(storage.token);
            if (!_.isEmpty(decode)) {
                return decode["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            }
        }
    }

    getProfileImage() {
        var storage = this.getSessionStorageData("user");
        if (storage) {
            return storage["picture"];
        }
    }

    isUser() {
        var roles = this.getUserRole();
        return roles && _.includes(roles.split(','), Constants.ROLE_STUDENT);
    }

    isAdmin() {
        var roles = this.getUserRole();
        return roles && _.some([Constants.ROLE_ADMIN, Constants.ROLE_SUPERADMIN], (role) => _.includes(roles, role));
    }

    isMentor() {
        var roles = this.getUserRole();
        return roles && _.some([Constants.ROLE_MENTOR, Constants.ROLE_ASSISTANT_MENTOR], (role) => _.includes(roles, role));
    }
}
export default new Helpers();