import { Col, Row } from 'antd';
import React from 'react';
import './Dashboard.scss';
import Announsments from './user-items/announcments/Announsments';
import Events from './user-items/events/Events';
import UserProjects from './user-items/user-projects/UserProjects';

const Dashboard = () => {
    return (
        <Row gutter={16}>
            <Col span={24}>
                <UserProjects />
            </Col>
            <Col span={12}>
                <Announsments />
            </Col>
            <Col span={12}>
                <Events />
            </Col>
        </Row>

    );
};

export default Dashboard;