import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KozaTransfer from "../../../../components/koza-transfer/KozaTransfer";
import { useTranslation } from 'react-i18next';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import { Spin } from 'antd';

const Step2 = props => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await ServiceHelper.get('/api/courses/getfortransfer', (status, result) => {
            if (status === 200) {
                setData(result);
                props.setAllCourses(result);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <Spin spinning={loading}>
            <KozaTransfer dataSource={data} selectedData={props.selectedData} setData={props.setPreCourses} titles={[t('all_courses'), t('selected_courses')]} locale={{ itemsUnit: t('course'), itemUnit: t('course') }} />
        </Spin>
    );
};

Step2.propTypes = {
    setPreCourses: PropTypes.func,
    setAllCourses: PropTypes.func
};

export default Step2;