import React, { useState } from 'react';
import './AdvancedSettings.scss';
import { Col, Menu, Row } from 'antd';
import { GlobalOutlined, BarsOutlined, PartitionOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CategoryOperationsIndex from './pages/category-operations/Index';
import EducationLanguageIndex from './pages/education-language/Index';
import LevelOperationsIndex from './pages/level-operations/Index';
import TrainingProvidersIndex from './pages/training-providers/Index';

const AdvancedSettings = () => {
    const { t } = useTranslation();

    const [menu, setMenu] = useState("training_providers");

    const renderMenuContent = () => {
        switch (menu) {
            case "training_providers":
                return <TrainingProvidersIndex />
            case "education_languages":
                return <EducationLanguageIndex />
            case "category_operations":
                return <CategoryOperationsIndex />
            case "level_operations":
                return <LevelOperationsIndex />

            default:
                break;
        }
    }

    const handleAdvancedSettingsMenuClick = e => {
        if (e) {
            setMenu(e.key);
        }
    };

    const items = [
        { "key": "training_providers", "label": t('training_providers'), "icon": <SafetyCertificateOutlined /> },
        { "key": "education_languages", "label": t('education_languages'), "icon": <GlobalOutlined /> },
        { "key": "category_operations", "label": t('category_operations'), "icon": <BarsOutlined /> },
        { "key": "level_operations", "label": t('level_operations'), "icon": <PartitionOutlined /> },
    ]

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Menu mode='inline' items={items} defaultSelectedKeys={["training_providers"]} onClick={handleAdvancedSettingsMenuClick} />
            </Col>
            <Col span={18}>
                {renderMenuContent()}
            </Col>
        </Row>
    );
};

export default AdvancedSettings;