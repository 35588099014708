import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import KozaForm from '../../../../../components/koza-form/KozaForm';
import { Col, Row, message } from 'antd';
import KozaFormItem from '../../../../../components/koza-form-item/KozaFormItem';

const SocialMedia = props => {

    var data = props.data.userDetails.socialMedia;
    console.log(data);

    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        await ServiceHelper.post('/api/users/profile/updatesocialmedia', values, (status, result) => {
            if (status === 200) {
                message.success(result);
                props.aftersave();
            }
        }).finally(() => setLoading(false));
    }
    return (
        <KozaForm onFinish={onFinish} loading={isLoading} initialValues={data}>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={16} xl={10}>
                    <KozaFormItem name='githubUrl' label='github_url' type="url" rules={[{ type: "url" }]} />
                    <KozaFormItem name='linkedinUrl' label='linkedin_url' type='url' rules={[{ type: "url" }]} />
                </Col>
            </Row>
        </KozaForm>

    );
};

SocialMedia.propTypes = {
    data: PropTypes.object.isRequired,
    aftersave: PropTypes.func
};

export default SocialMedia;