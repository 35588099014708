import React, { useEffect, useState } from 'react';
import './PrivateLayout.scss';
import _ from 'lodash';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout, Menu, theme, Dropdown, Avatar, Space, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined, TeamOutlined, ApartmentOutlined, DownOutlined,
    AppstoreAddOutlined, SettingOutlined, AppstoreOutlined
} from '@ant-design/icons';
import XLangSelect from '../../components/koza-lang-select/KozaLangSelect';
import Helpers from '../../helpers/Helpers';
import Constants from '../../helpers/Constants';

import trTR from 'antd/lib/locale/tr_TR';
import enUS from 'antd/lib/locale/en_US';

const { Header, Sider, Content, Footer } = Layout;

const PrivateLayout = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();

    var lang = localStorage.getItem("lang") || "tr";

    const [collapsed, setCollapsed] = useState(false);
    const [locale, setLocal] = useState(lang === "tr" ? trTR : enUS);
    const [openMenuKeys, setOpenMenuKeys] = useState(['dashboard']);

    const { token: { colorBgContainer } } = theme.useToken();

    useEffect(() => {
        if (!Helpers.isAuthenticate())
            navigate("/auth/login");
    }, []);

    const handleClick = e => {
        if (e) {
            switch (e.key) {
                case "dashboard":
                    navigate("/panel/dashboard");
                    break;
                case "role_list":
                    navigate("/panel/roles/list");
                    break;
                case "role_add":
                    navigate("/panel/roles/add");
                    break;
                case "user_list":
                    navigate("/panel/users/list");
                    break;
                case "course_add":
                    navigate("/panel/courses/add");
                    break;
                case "course_list":
                    navigate("/panel/courses/list");
                    break;
                case "project_add":
                    navigate("/panel/projects/add");
                    break;
                case "project_list":
                    navigate("/panel/projects/list");
                    break;
                case "user_project_list":
                    navigate("/panel/projects/user/projects");
                    break;
                case "user_program_detail":
                    navigate("/panel/projects/user/project-detail");
                    break;
                case "advanced_settings":
                    navigate("/panel/advanced-settings");
                    break;
                default:
                    break;
            }
        }
    };

    const onMenuOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openMenuKeys.indexOf(key) === -1);
        var rootSubmenuKeys = _.map(getLeftMenuByUserRole(), "key");
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenMenuKeys(keys);
        } else {
            setOpenMenuKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    function renderUserHeaderMenu() {
        var profileImage = Helpers.getProfileImage();
        var userName = Helpers.getUserName();

        return <>
            {
                profileImage
                    ? <Avatar src={profileImage} id="user_profile_image" />
                    : userName && <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{userName.charAt(0)}</Avatar>
            }
            <span>{userName}</span>
        </>
    }

    const items = [
        {
            key: 'profile',
            label: (<div onClick={() => navigate("/panel/user/profile")}>{t("profile")}</div>),
        },
        {
            type: 'divider',
        },
        {
            key: 'logout',
            label: (<div onClick={() => navigate("/auth/login")}>{t("logout")}</div>)
        },
    ];

    const admin_left_menu = [
        { key: 'dashboard', label: t("dashboard"), icon: <HomeOutlined />, },
        {
            key: 'user_management', label: t("user_management"), icon: <TeamOutlined />, children: [
                { key: "user_list", label: t("user_list") }
            ]
        },
        {
            key: 'role_management', label: t("role_management"), icon: <ApartmentOutlined />, children: [
                // { key: "role_add", label: t("add_new") },
                { key: "role_list", label: t("role_list") }
            ]
        },
        {
            key: 'course_management', label: t("course_management"), icon: <AppstoreAddOutlined />, children: [
                { key: "course_add", label: t("course_add") },
                { key: "course_list", label: t("course_list") }
            ]
        },
        {
            key: 'program_management', label: t("program_management"), icon: <AppstoreOutlined />, children: [
                { key: "project_add", label: t("project_add") },
                { key: "project_list", label: t("project_list") }
            ]
        },
        { key: 'advanced_settings', label: t("advanced_settings"), icon: <SettingOutlined />, },
    ]

    const user_left_menu = [
        { key: 'dashboard', label: t("dashboard"), icon: <HomeOutlined />, },
        { key: 'user_project_list', label: t("siber_koza_projects"), icon: <AppstoreOutlined />, },
    ]

    function getLeftMenuByUserRole() {
        var role = Helpers.getUserRole();

        if (role === Constants.ROLE_ADMIN || role === Constants.ROLE_SUPERADMIN)
            return admin_left_menu;
        else if (role === Constants.ROLE_STUDENT)
            return user_left_menu;
        else
            return user_left_menu;
    }

    return (
        <ConfigProvider prefixCls={Helpers.prefixCls} iconPrefixCls={Helpers.prefixCls} locale={locale}>
            <Layout id='private-layout-container'>
                <Sider collapsible collapsed={collapsed} width={250} onCollapse={(value) => setCollapsed(value)}>
                    {
                        !collapsed
                            ? <img id='logo' src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZA_KOYUZEMIN.svg`} alt="SIBERKOZA" onClick={() => navigate("/panel/dashboard")} />
                            : <img id='logo-small' src={`${process.env.PUBLIC_URL}/assets/img/logo/SIBERKOZAFAVICON.svg`} alt="SIBERKOZA" onClick={() => navigate("/panel/dashboard")} />
                    }
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={handleClick} items={getLeftMenuByUserRole()} onOpenChange={onMenuOpenChange} />
                </Sider>
                <Layout id="site-layout">
                    <Header style={{ background: colorBgContainer }}>
                        {
                            React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                { className: 'trigger', onClick: () => setCollapsed(!collapsed) })
                        }
                        <div className='d-flex align-items-center'>
                            <div id='lang-container'>
                                <XLangSelect onChange={(value) => setLocal(value === "tr" ? trTR : enUS)} />
                            </div>
                            <Dropdown className='user-header-menu-container' menu={{ items }} trigger={['click']}>
                                <Space>
                                    {renderUserHeaderMenu()}
                                    <DownOutlined />
                                </Space>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content id='private-layout-content'>
                        <div id='private-layout-inner-content'>
                            <Outlet />
                        </div>
                    </Content>
                    <Footer>
                        Siber Koza ©{new Date().getFullYear()} by <a href='https://www.crypttech.com/' target="_blank">CRYPTTECH</a>
                    </Footer>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
};

export default PrivateLayout;