import React from 'react';
import './KozaModal.scss';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const KozaModal = props => {
    const { t } = useTranslation();

    return (
        <Modal title={t(props.title)} open={props.open} onOk={props.handleOk} onCancel={props.handleCancel}
            okText={t(props.okText)} cancelText={t(props.cancelText)}
            afterClose={props.afterClose} destroyOnClose={true} confirmLoading={props.confirmLoading}>
            {props.children}
        </Modal>
    );
};

KozaModal.defaultProps = {
    okText: 'save',
    cancelText: 'cancel'
}

KozaModal.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    handleOk: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    afterClose: PropTypes.func,
    confirmLoading: PropTypes.bool
};

export default KozaModal;