import React, { useEffect, useState } from 'react';
import './Programs.scss';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, message, Space, Tag, Tooltip } from 'antd';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import KozaCard from '../../../../components/koza-card/KozaCard';
import KozaTable from '../../../../components/koza-table/KozaTable';
import ServiceHelper from '../../../../../helpers/ServiceHelper';

const Programs = () => {

    const { t } = useTranslation();
    let navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get('/api/programs/getall', (status, result) => {
            if (status === 200) {
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    function renderStatusTag(status) {
        switch (status) {
            case 1:
                return <Tag color='purple'>{t('passive')}</Tag>
            case 2:
                return <Tag color='magenta'>{t('deleted')}</Tag>
            default:
                return <Tag color='geekblue'>{t('active')}</Tag>
        }
    }

    const columns = [
        {
            title: t('program_name'),
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: item => <span>{t(item)}</span>
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            render: item => renderStatusTag(item)
        },
        {
            title: t('level'),
            dataIndex: 'level',
            key: 'level',
            sorter: (a, b) => a.level.name.localeCompare(b.level.name),
            render: item => <span>{t(item.name)}</span>
        },
        {
            title: t('category'),
            dataIndex: 'category',
            key: 'category',
            render: item => <span>{_.join(item, " , ")}</span>
        },
        {
            title: t('language'),
            dataIndex: 'language',
            key: 'language',
            render: item => <span>{_.join(item, " , ")}</span>
        },
        {
            title: t('course_count'),
            dataIndex: 'courseCount',
            key: 'courseCount',
            render: item => <span>{item}</span>
        },
        {
            title: t('action'),
            key: '',
            align: "right",
            render: (text, record) => (
                record.status !== 2 && <Space size="middle">
                    <a onClick={() => navigate(`/panel/projects/user/project-detail/${record.id}`)}>{t('view')}</a>
                </Space>
            ),
        },
    ];

    return (
        <KozaCard title='siber_koza_projects' extra={<Space>
            <Tooltip title={t('refresh')}>
                <Button shape="circle" icon={<ReloadOutlined />} onClick={getData} />
            </Tooltip>
        </Space>}>
            <KozaTable dataSource={data} columns={columns} isLoading={isLoading} size="small" expandedRowRender={(record) => (
                <ul>
                    <li><strong>{t('mentors')}: </strong>{_.join(record.mentors, " , ")}</li>
                    <li><strong>{t('assistant_mentors')}: </strong>{_.join(record.assistantMentors, " , ")}</li>
                </ul>
            )} />
        </KozaCard>
    );
};

export default Programs;