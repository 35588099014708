import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helpers from './helpers/Helpers';

import Login from './public/pages/auth/login/Login';
import Register from './public/pages/auth/register/Register';
import ForgotPassword from './public/pages/auth/forgot-password/ForgotPassword';
import ConfirmEmail from './public/pages/auth/confirm-email/ConfirmEmail';

import PrivateLayout from './private/layout/PrivateLayout';
import Dashboard from './private/pages/dashboard/Dashboard';
import AdvancedSettings from './private/pages/advanced-settings/AdvancedSettings';

import Profile from './private/pages/user-operations/profile/Profile';

import Users from './private/pages/user-managements/users/Users';
import Roles from './private/pages/role-managements/roles/Roles';
import Role from './private/pages/role-managements/role/Role';

import Course from './private/pages/course-management/course/Course';
import Courses from './private/pages/course-management/courses/Courses';

import Program from './private/pages/program-management/program/Program';
import Programs from './private/pages/program-management/programs/Programs';
import UserPrograms from './private/pages/program-management/user/programs/Programs';
import UserProgramDetail from './private/pages/program-management/user/program/Program';

import Page404Private from './private/errors/page404/Page404';
import Page404Public from './public/errors/page404/Page404';

const App = () => {

  const { _, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(Helpers.getLang());
  }, []);

  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/auth'>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="confirmemail">
          <Route path=":q" element={<ConfirmEmail />} />
        </Route>
        <Route path="forgot-password" element={<ForgotPassword />}>
          <Route path=":q" element={<ForgotPassword />} />
        </Route>
      </Route>

      <Route path='/panel' element={<PrivateLayout />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='advanced-settings' element={<AdvancedSettings />} />

        <Route path='user'>
          <Route path='profile' element={<Profile />} />
        </Route>

        <Route path='users'>
          <Route path='list' element={<Users />} />
        </Route>

        <Route path='roles'>
          <Route path='list' element={<Roles />} />
          {/* <Route path='add' element={<Role />} /> */}
          <Route path='edit'>
            <Route path=':id' element={<Role />} />
          </Route>
        </Route>

        <Route path='courses'>
          <Route path='add' element={<Course />} />
          <Route path='list' element={<Courses />} />
          <Route path='edit'>
            <Route path=':id' element={<Course />} />
          </Route>
        </Route>

        <Route path='projects'>
          <Route path='add' element={<Program />} />
          <Route path='list' element={<Programs />} />
          <Route path='edit'>
            <Route path=':id' element={<Program />} />
          </Route>
          
          <Route path='user'>
            <Route path='projects' element={<UserPrograms />} />
            <Route path='project-detail'>
              <Route path=':id' element={<UserProgramDetail />} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<Page404Private />} />
      </Route>
      <Route path='*' element={<Page404Public />} />
    </Routes>
  );
};

export default App;