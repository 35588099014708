import React, { useEffect, useState } from 'react';
import './UserProjects.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KozaCard from '../../../../components/koza-card/KozaCard';
import KozaTable from '../../../../components/koza-table/KozaTable';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';

const UserProjects = props => {

    const { t } = useTranslation();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await ServiceHelper.get("/api/programs/getuserprograms", (status, result) => {
            if (status === 200) {
                console.log(result)
                setData(result);
            }
        }).finally(() => setLoading(false));
    }

    const columns = [
        {
            title: t('program_name'),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t('progress_status'),
            dataIndex: 'percentage',
            key: 'percentage',
            render: (item) => <span>-</span>
        },
        {
            title: t('action'),
            key: '',
            align: "right",
            render: (text, record) => (
                record.status !== 2 && <Space size="middle">
                    <a onClick={() => navigate(`/panel/projects/user/project-detail/${record.id}`)}>{t('view')}</a>
                </Space>
            ),
        },
    ];
    return (
        <KozaCard loading={loading} title={t('registered_siber_koza_projects')} classname="mb-20">
            <KozaTable dataSource={data} columns={columns} />
        </KozaCard>
    );
};

UserProjects.propTypes = {

};

export default UserProjects;